import React from 'react'
import Layout from '../../components/layout'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from '../../utils/seo';


export default function BunFestivalMap() {
    return (
        <Layout>
            <Container>
                <Row>
                    <Col>
                        <h2 className='about_slogan'>長洲地圖｜AR打卡點 及 必到景點</h2>
                        <iframe className='pt-3' title="長洲地圖｜AR打卡點 及 必到景點" src="https://www.google.com/maps/d/embed?mid=1yql1cqpFfli40wptDOpYLmKMudm9Bow&ehbc=2E312F" width="100%" height="600"></iframe>
                        <hr></hr>
                        <section className='pb-3'>
                            <p>以上項目資料僅供參考，如發現資料有誤，歡迎<a className="temple-detail-contact" href="mailto:support@m2gether.com">電郵</a>指正以便我們了解及跟進。</p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}


export const Head = () => (
    <Seo
        title="長洲太平清醮2023 活動時間表 + 飄色巡遊路線 + 交通小貼士"
        description="包山2023/長洲｜說到香港的傳統盛事，不得不提的就是長洲太平清醮了！自2005年復辦搶包山比賽，每逢太平清醮長洲都會出現大量人流，不少遊客也會專程前往感受熱鬧"
        siteUrl="http://m2gether.com/bunfestival"
    />
)




